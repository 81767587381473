import React, { useState } from 'react';
import styled from 'styled-components';
import ImageViewer from 'react-simple-image-viewer';

const Wrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  ${({ limitWidth }) =>
    limitWidth && `max-width: ${limitWidth}px;\nmargin: auto;`}
`;

const ImageWrapper = styled.div`
  flex: 1 1 auto;
`;

const ClickableImageWrapper = styled.button`
  flex: 1 1 auto;
  cursor: pointer;
`;

const Image = styled.img`
  max-width: 100%;
`;

export function ImageContainer({
  images,
  alts,
  clickable = false,
  limitWidth = null,
}) {
  const [currentImage, setCurrentImage] = useState(null);
  const SelectedWrapper = clickable ? ClickableImageWrapper : ImageWrapper;

  return (
    <Wrapper limitWidth={limitWidth}>
      {images.map((image, index) => (
        <SelectedWrapper
          key={image}
          onClick={clickable ? () => setCurrentImage(image) : undefined}
        >
          <Image src={image} alt={alts[index]} />
        </SelectedWrapper>
      ))}
      {currentImage && (
        <ImageViewer
          src={[currentImage]}
          disableScroll
          closeOnClickOutside
          onClose={() => setCurrentImage(null)}
          backgroundStyle={{
            backgroundColor: 'rgba(0,0,0,0.9)',
          }}
        />
      )}
    </Wrapper>
  );
}
